import { Splash } from "../../../../shared/Splash"
import CareTeamCards from "./images/care-team-cards.svg"

export const HeroSection = () => {
  return (
    <Splash
      titleText="Meet your Cerebral care team"
      subtitleText="Our clinicians are known for their unrivaled expertise and their big hearts. We don’t just treat patients—we care for them."
      image={CareTeamCards}
      background="linear-gradient(141deg, #FEF9EC 0%, #ECEFFE 100%)"
      buttons={[
        { text: "Find a therapist", link: "/care-team/therapists" },
        { text: "Find a prescriber", link: "/care-team/prescribers" },
      ]}
      shouldHideMobileImage
    />
  )
}
