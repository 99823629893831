import styled from "styled-components"

export const CliniciansContainer = styled.div`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
  text-align: center;
  padding-bottom: 54px;
  h3 {
    font-size: 22px;
  }
  @media (max-width: 834px) {
    h3 {
      font-size: 20px;
    }
  }
`

export const CliniciansButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 24px auto;
  a {
    width: 215px;
    height: 56px;
  }

  @media (max-width: 834px) {
    flex-wrap: wrap;
    a {
      width: 100%;
      margin: 0 32px;
      max-width: 315px;
    }
  }
`
