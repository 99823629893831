import styled from "styled-components"

export const QualityContainer = styled.div`
  padding: 74px 10%;
  h2,
  p {
    text-align: center;
    margin: 0px auto 24px;
    max-width: 465px;
  }
  @media (max-width: 834px) {
    h2,
    p {
      text-align: left;
    }
  }
`

export const CopyGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px;
  margin: 48px auto;
  max-width: 1300px;
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`

export const CopyGridSection = styled.div`
  display: flex;
  gap: 8px;
  justify-self: center;
  flex-wrap: nowrap;
  p {
    text-align: left;
  }
`
