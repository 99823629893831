import {
  CerebralWaySection,
  Circle,
  CopySection,
  DesktopGetToKnow,
  ImageContainer,
  MobileGetToKnow,
  TheCerebralWayContainer,
  TheCerebralWayOuterContainer,
  VerticalLine,
} from "./styled"
import HomeScreen from "./images/home-screen.svg"
import ManDrinkingCoffee from "./images/man-drinking-coffee.webp"
import { BodyRegular, Button, H2, H4, H6 } from "../../../../ui"
import { SmallRatings } from "../../../../shared"

export const TheCerebralWay = () => {
  return (
    <TheCerebralWayOuterContainer>
      <TheCerebralWayContainer>
        <ImageContainer>
          <img src={HomeScreen} alt="" />
        </ImageContainer>
        <CopySection>
          <H2>We can't wait to meet you!</H2>
          <VerticalLine />
          <CerebralWaySection>
            <Circle>1</Circle>
            <div>
              <H4>Join the Cerebral family</H4>
              <BodyRegular>
                Create an account, tell us about yourself, and we'll help you
                find the right plan to reach your mental health goals.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <CerebralWaySection>
            <Circle>2</Circle>
            <div>
              <H4>We recommend, you choose</H4>
              <BodyRegular>
                We'll recommend clinicians based on your needs and preferences,
                and you choose the one you like best.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <DesktopGetToKnow>
            <img src={ManDrinkingCoffee} alt="" />
            <div>
              <H6>Get to know one another</H6>
              <BodyRegular>
                It's time for your first session! Meet with your clinician, get
                to know one another, and learn about their treatment process.
              </BodyRegular>
            </div>
          </DesktopGetToKnow>
          <MobileGetToKnow>
            <img src={ManDrinkingCoffee} alt="" />
            <H6>
              Get to know
              <br />
              one another
            </H6>
            <BodyRegular>
              It's time for your first session! Meet with your clinician, get to
              know one another, and learn about their treatment process.
            </BodyRegular>
          </MobileGetToKnow>
          <CerebralWaySection>
            <Circle>3</Circle>
            <div>
              <H4>We're with you, every step of the way</H4>
              <BodyRegular>
                Your clinician will work with you to set achievable goals for
                your mental health, and help you reach them.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <Button text="Start today" to={`${process.env.GATSBY_START_URL}`} />
          <SmallRatings />
        </CopySection>
      </TheCerebralWayContainer>
    </TheCerebralWayOuterContainer>
  )
}
